import React, { useState, useEffect } from 'react';

// REACT ICONS
import {GiSmartphone} from 'react-icons/gi';
import {BsEnvelopeFill} from 'react-icons/bs';
import {TiSocialInstagram} from 'react-icons/ti';
import {RiLinkedinBoxFill} from 'react-icons/ri';
import {FaGithubSquare} from 'react-icons/fa';

function Contact(){

    const phone = '(+33)6.46.11.35.65';
    const mail = 'contact.mjacquens@gmail.com';

    const [contactDisplay,setContactDisplay] = useState('flex');

    useEffect(() => {
        function handleResize() {
            
          if(window.scrollY > 0.1){
            setContactDisplay('none')  
          }else{
            setContactDisplay('flex') 
          }
        
    }
    
        window.addEventListener('scroll', handleResize)
      })


    return(

        <div>

        <div className="contact" style={{display:contactDisplay}}>

            <div  className="socialNetwork">
                <a href="https://www.linkedin.com/in/marine-jacquens/"><RiLinkedinBoxFill size={25}/></a>
                <a href="https://www.instagram.com/post_it_code/"><TiSocialInstagram size={25}/></a>
                <a href="https://github.com/marine-jacquens?tab=repositories"><FaGithubSquare size={23}/></a>
            </div>
            <div className="personnalContact">
                <p className="phone"><GiSmartphone color="white" size={25}/>{phone}</p>
                <a href="mailto:contact.mjacquens@gmail.com" className="mail"><BsEnvelopeFill size={25} style={{marginRight:'2%'}}/>{mail}</a>
            </div>     

                
    
        </div>

        </div>    
    )
}

export default Contact