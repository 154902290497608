import React from 'react';

// COMPONENTS
import ServiceProvided from '../components/services_provided';

// React icons
import {BsLightbulb} from 'react-icons/bs';
import {AiOutlineFileSearch} from 'react-icons/ai';
import {MdComputer,MdOutlineDesignServices} from 'react-icons/md';
import {ImStatsDots} from 'react-icons/im';
import {GoMegaphone} from 'react-icons/go';

class Services extends React.Component {

    render() {
  
      let services_details = [
  
        {
          cat:"technical_skills",
          title:"CONCEPTION SYSTÈME & MAQUETTAGE",
          paragraph:"Diagramme de conception (UML), rédaction cahier des charges, zoning, wireframe, prototype.",
          icon:<BsLightbulb size={30}/>,
        },
  
        {
          cat:"technical_skills",
          title:"GESTION DE PROJET WEB",
          paragraph:"Création et mise en ligne de site vitrine, e-commerce, solution personnalisée, application web et mobile.",
          icon:<MdComputer size={30}/>,
        },
  
        {
          cat:"technical_skills",
          title:"REFONTE SITE & APPLICATION",
          paragraph:"Audit performance, stratégie UX-UI, mise à jour.",
          // paragraph:"Audit performance, stratégie UX-UI, mise à jour, migration.",
          icon:<MdOutlineDesignServices size={30}/>,
        },
  
        {
          cat:"strategy_skills",
          title:"REFERENCEMENT SEO-SEA",
          paragraph:"Optimisation des ancres et mots-clés, stratégie de netlinking, campagne publicitaire (RS et Google ads), remarketing - retargeting.",
          icon:<AiOutlineFileSearch size={30}/>,
        },
  
        {
          cat:"strategy_skills",
          title:"STRATEGIE MARKETING DIGITAL",
          paragraph:"Benchmark, étude de marché, business plan.",
          icon:<ImStatsDots size={30}/>,
        },
  
        {
          cat:"strategy_skills",
          title:"COMMUNICATION",
          paragraph:"Stratégie de communication (web), conception graphique, campagne mailing.",
          icon:<GoMegaphone size={30}/>,
        }
  
  
  
      ] 
        
      return(
  
        <div id="services" className="services_section">

          <div className="sectionTitle">
            <h2>MES SERVICES</h2>
            <h3>Un accompagnement adapté à votre projet</h3>
          </div>
          
          <div className="hr"><p></p></div>
          <ServiceProvided services_details={services_details}/>
         
        </div>
      )
    }
  
  }

  export default Services