import React from 'react';

function ServiceProvided(props) {

  return (

    <div  className="skills">

      <div className="technical_skills">

      {props.services_details.map((service_detail, i) => (

        service_detail.cat === "technical_skills" ?

        <div key={i} className="technical_skills_icons">
        
          <div className="skills_text">

            <h4>{service_detail.title}</h4> 
            <p>{service_detail.paragraph}</p>
          </div>

          {<div className="skill_icon"><span className="light">{service_detail.icon}</span></div>} 
        
        </div>

        : null

      ))}

      </div>

      <div className="strategy_skills">

        {props.services_details.map((service_detail, i) => (

          service_detail.cat === "strategy_skills" ?

          <div className="strategy_skills_icons">

          {<div className="skill_icon"><span className="light">{service_detail.icon}</span></div>}
            
            <div className="skills_text">
              
                <h4>{service_detail.title}</h4>
                <p>{service_detail.paragraph}</p>

              </div>

          </div>

          : null
              
        ))}


      
      </div>

    </div>
    
      
  );
}

export default ServiceProvided