import React from 'react';
import ReactDOM from 'react-dom';

// Stylesheets

import './assets/css/header/social_network.css';
import './assets/css/header/navbar.css';
import './assets/css/header/button_next.css';
import './assets/css/footer.css';
import './assets/css/mandatoryInfoModal.css';


import './assets/css/services.css';
import './assets/css/skills.css';
import './assets/css/responsive.css';
import './assets/css/portfolio.css';
import './assets/css/prices.css';


import './assets/css/general/general.css';
import './assets/css/general/fonts.css';
import './assets/css/general/button_up.css';

// PAGES
import Home from './pages/home';
import Services from './pages/services';
import Skills from './pages/skills';
import Responsive from './pages/responsive';
import Portfolio from './pages/portfolio';
import Prices from './pages/prices';


// COMPONENTS
import ButtonUp from './components/button_up';
import Footer from './components/footer';
import MandatoryInfoModal from './components/mandatoryInformationModal';
import MandatoryInfoLinks from './components/mandatoryInfoLinks';

class ServicesWebSite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open:false, 
        modalIndex:0,
    };
  }

  handleOpenMandatoryModal(){

    this.setState({open:true})

  }

  handleChangeModalNumber(props){
    
    this.setState({modalIndex:props})
    this.handleOpenMandatoryModal()

  }

  handleCloseMandatoryModal(){
        this.setState({open:false})
  }

  render() {

    const legalInfoModal = [

      {
          title:<h2>Mentions légales et CGU</h2>,
          content:[
              {
                  title:<h3>Définitions</h3>,
                  paragraph:[
                      <p><b>Client :</b> tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des présentes conditions générales.</p>,
                      <p><b>Prestations et Services :</b> ce que <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> met à disposition des Clients.</p>,
                      <p><b>Contenu :</b> Ensemble des éléments constituants l’information présente sur le Site, notamment textes – images – vidéos.</p>,
                      <p><b>Informations clients :</b> Ci après dénommé « Information (s) » qui correspondent à l’ensemble des données personnelles susceptibles d’être détenues par <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> pour la gestion de la relation client et à des fins d’analyses et de statistiques.</p>,
                      <p><b>Utilisateur :</b> Internaute se connectant, utilisant le site susnommé.</p>,
                      <p><b>Informations personnelles :</b> « Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</p>,
                      <p>Les termes « données à caractère personnel », « personne concernée », « sous traitant » et « données sensibles » ont le sens défini par le Règlement Général sur la Protection des Données (RGPD : n° 2016-679)</p>
                  ], 
              },
              {
                  title:<h3>1. Présentation du site internet</h3>,
                  paragraph:[
                      <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi:</p>,
                      <h4>Mentions d'identification</h4>,
                      <p><strong>Propriétaire</strong> :   Marine Jacquens EI <br/><strong>Adresse postale</strong> : 14 avenue Maréchal Juin 20090 Ajaccio<br/><strong>Contact</strong> : 06 46 11 35 65<br/><strong>Email</strong> : marine.jacquens@gmail.com<br/></p>,
                      <h4>Mentions relatives à l'hébergeur</h4>,
                      <p><strong>Nom - Raison sociale</strong> : OVH <br/><strong>Adresse de l'hébergeur</strong> : 2 rue Kellermann 59100 Roubaix 1007<br/><strong>Numéro de SIRET hébergeur</strong> : 42476141900045</p>
                  ],
              },
              {
                  title:<h3 id="CGU">2. Conditions générales d’utilisation du site et des services proposés</h3>,
                  paragraph:[
                      <p>Le Site constitue une œuvre de l’esprit protégée par les dispositions du Code de la Propriété Intellectuelle et des Réglementations Internationales applicables. Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre compte tout ou partie des éléments ou travaux du Site.</p>,
                      <p>L’utilisation du site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> sont donc invités à les consulter de manière régulière.</p>,
                      <p>Ce site internet est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention. Le site web <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> est mis à jour régulièrement par <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> responsable. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>,
                  ],
              },
              {
                  title:<h3>3. Description des services fournis</h3>,
                  paragraph:[
                      <p>Le site internet <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> a pour objet de fournir une information concernant l’ensemble des activités de la société.<a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> s’efforce de fournir sur le site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>, 
                      <p>Toutes les informations indiquées sur le site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>,
                  ],
              },
              {
                  title:<h3>4. Limitations contractuelles sur les données techniques</h3>,
                  paragraph:[
                      <p>Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour Le site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> est hébergé chez un prestataire sur le territoire de l’Union Européenne conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>,
                      <p>L’objectif est d’apporter une prestation qui assure le meilleur taux d’accessibilité. L’hébergeur assure la continuité de son service 24 Heures sur 24, tous les jours de l’année. Il se réserve néanmoins la possibilité d’interrompre le service d’hébergement pour les durées les plus courtes possibles notamment à des fins de maintenance, d’amélioration de ses infrastructures, de défaillance de ses infrastructures ou si les Prestations et Services génèrent un trafic réputé anormal.</p>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> et l’hébergeur ne pourront être tenus responsables en cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du matériel informatique et de téléphonie lié notamment à l’encombrement du réseau empêchant l’accès au serveur.</p>,
                  ],
              },
              {
                  title:<h3>5. Propriété intellectuelle et contrefaçons</h3>,
                  paragraph:[
                      <p>Les images d'habillage du site et arrière plan de présentation des projets ont été sélectionnés sur <a href="https://unsplash.com/license">unsplash</a> et <a href="https://www.freepng.fr/">freepng</a>.<br/>Les icônes ont été implémentées via la librairie <a href="https://react-icons.github.io/react-icons/">react-icons</a>.</p>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tout autre élément non cité ci-dessus et accessible sur le site internet, notamment les textes, images, graphismes, logos, vidéos et sons. Toute reproduction, représentation, modification, publication, adaptation de tout ou une partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>.</p>,
                      <p>Toute exploitation non autorisée du site ou de d’un quelconque élément qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>,
                  ],
              },
              {
                  title:<h3>6. Limitations de responsabilité</h3>,
                  paragraph:[
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> agit en tant qu’éditeur du site. <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>  est responsable de la qualité et de la véracité du Contenu qu’il publie. </p>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site internet <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>.
                      Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).</p>,
                  ],

              },
              {
                  title:<h3>7. Gestion des données personnelles</h3>,
                  paragraph:[
                      <p>Le Client est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679). </p>,
                      <h4>7.1 Responsables de la collecte des données personnelles</h4>,
                      <p>En tant que responsable du traitement des données qu’il collecte, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de ses traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité.
                      Chaque fois que <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> traite des Données Personnelles, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> les traite.</p>,
                      <h4>7.2 Finalité des données collectées</h4>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> est susceptible de traiter tout ou une partie des données : </p>,
                      <ul>
                          <li>pour permettre la navigation sur le site et la gestion et la traçabilité des prestations et services commandés par l’utilisateur : données d’utilisation du site, facturation, historique des commandes, etc. </li>
                          <li>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP) </li>
                          <li>pour améliorer la navigation sur le site : données d’utilisation </li>
                          <li>pour mener des enquêtes de satisfaction facultatives sur <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, donnée concernée : adresse email </li>
                          <li>pour mener des campagnes de communication (sms, mail), données concernées : numéro de téléphone, adresse email</li>
                      </ul>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.</p>,
                      <h4>7.3 Droit d’accès, de rectification et d’opposition</h4>,
                      <p>Conformément à la réglementation européenne en vigueur, les utilisateurs de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> disposent des droits suivants : </p>,
                      <ul>
                          <li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des utilisateurs droit de verrouillage ou d’effacement des données des utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite </li>
                          <li>droit de retirer à tout moment un consentement (article 13-2c RGPD) </li>
                          <li>droit à la limitation du traitement des données des utilisateurs (article 18 RGPD) </li>
                          <li>droit d’opposition au traitement des données des utilisateurs (article 21 RGPD) </li>
                          <li>droit à la portabilité des données que les utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD) </li>
                          <li>droit de définir le sort des données des utilisateurs après leur mort et de choisir à qui <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
                      </ul>,
                      <p>Dès que <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> a connaissance du décès d’un utilisateur et à défaut d’instructions de sa part, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.</p>,
                      <p>Si l’utilisateur souhaite savoir comment <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’utilisateur peut contacter <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> par écrit à l’adresse mail suivante : <a href="mailto:marine.jacquens@gmail.com">marine.jacquens@gmail.com</a>.</p>,
                      <p>Dans ce cas, l’utilisateur doit indiquer les données personnelles qu’il souhaiterait que <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport). </p>,
                      <p>Les demandes de suppression de données personnelles seront soumises aux obligations qui sont imposées à <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les utilisateurs de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la <a href="https://www.cnil.fr/fr/plaintes">CNIL</a>.</p>,
                      <h4>7.4 Non-communication des données personnelles</h4>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> s’interdit de traiter, héberger ou transférer les informations collectées sur ses clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’il présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>,
                      <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ne collecte aucune « données sensibles ».</p>,
                      <p>Les Données Personnelles de l’utilisateur peuvent être traitées par des filiales de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.</p>,
                      <p>Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des utilisateurs de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> sont principalement les agents de notre service client.</p>,

                  ],
              },
              {
                  title:<h3>8. Notification d’incident</h3>,
                  paragraph:[
                      <p>Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.</p>,
                      <p>Aucune information personnelle de l'utilisateur du site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>.</p>,
                      <h4>Sécurité</h4>,
                      <p>Pour assurer la sécurité et la confidentialité des données personnelles, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> utilise des réseaux protégés par des dispositifs standards tels que par pare-feu. </p>,
                      <p>Lors du traitement des Données Personnelles, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
               
                  ],

              },
              {
                //   title:<h3>9. Liens hypertextes « cookies » et balises (“tags”) internet</h3>,
                  title:<h3>9. Liens hypertextes</h3>,
                  paragraph:[
                      <p>Le site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>. Cependant, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>,
                    //   <p>Sauf si vous décidez de désactiver les cookies, vous acceptez que le site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que cela peut réduire ou empêcher l’accessibilité à tout ou partie des Services proposés par le site.</p>,
                    //   <h4>9.1. « COOKIES »</h4>,
                    //   <p>Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’utilisateur et enregistré au sein du terminal de l’utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’utilisateur, le fournisseur d’accès Internet de l’utilisateur, le système d’exploitation de l’utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’utilisateur.</p>,
                    //   <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> est susceptible de traiter les informations de l’utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> d’améliorer le contenu du Site, de la navigation de l’utilisateur.</p>,
                    //   <p>Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> informe l’utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.</p>,
                    //   <p>Si l’utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’utilisateur supprime ceux qui y sont enregistrés, l’utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.</p>,
                    //   <p>Le cas échéant, <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, résultant (i) du refus de Cookies par l’utilisateur (ii) de l’impossibilité pour <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’utilisateur. Pour la gestion des Cookies et des choix de l’utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’utilisateur peut modifier ses souhaits en matière de Cookies.</p>,
                    //   <p>À tout moment, l’utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.</p>,
                    //   <p>Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> ou dans son application mobile et si l’utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).</p>,
                    //   <p>Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>. À tout moment, l’utilisateur peut néanmoins revenir sur son consentement à ce que <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> dépose ce type de cookies.</p>,
                    //   <h4>Article 9.2. BALISES (“TAGS”) INTERNET</h4>,
                    //   <p><a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’utilisateur) dans un pays étranger.</p>,
                    //   <p>Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci. </p>,
                    //   <p>Cette technologie permet à <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’utilisateur. </p>,
                    //   <p>Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a>, et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.</p>,

                  ],

              },
              {
                  title:<h3>10. Droit applicable et attribution de juridiction</h3>,
                  paragraph:[
                      <p>Tout litige en relation avec l’utilisation du site <a href="https://www.marine-jacquens.com">https://www.marine-jacquens.com</a> est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents d'Ajaccio.</p>,
               
                  ],

              },
          ]

      },

      {
          title:<h2>Conditions générales de ventes</h2>,
          content:[
              {
                  title:<h3>APERÇU</h3>,
                  paragraph:[
                      <p>Ce site web est exploité par Le Bureau. Partout sur le site, nous employons les termes « nous », « notre » et « nos » en référence à Le Bureau. Ce site web, y compris l'ensemble des informations, outils et services auquel il donne accès, est offert par Le Bureau à l'utilisateur que vous êtes, à condition que vous acceptiez la totalité des modalités, conditions, politiques et avis stipulés ici.</p>,
                      <p>
                          En visitant notre site et/ou en achetant quelque chose auprès de notre entreprise, vous prenez part à notre « Service » et acceptez d'être lié(e) par les modalités et conditions suivantes (« Conditions générales », « Conditions d'utilisation »), y compris par les modalités, conditions et politiques mentionnées aux présentes et/ou accessibles en hyperlien. Les présentes Conditions d'utilisation s'appliquent à tous les utilisateurs du Site, y compris, sans s'y limiter, aux individus qui sont des visiteurs, des fournisseurs, des clients, des marchands et/ou des fournisseurs de contenu. <br/>
                          Veuillez lire attentivement les présentes Conditions d'utilisation avant d'accéder à notre site web et de l'utiliser. En accédant à une quelconque partie du Site ou en l'utilisant, vous acceptez d'être lié(e) par les présentes Conditions d'utilisation. Si vous n'acceptez pas la totalité des modalités et conditions du présent accord, vous ne pourrez peut-être pas accéder au site web ou utiliser ses services. Si les présentes Conditions d'utilisation sont considérées comme une offre, leur acceptation se limite expressément à elles.
                      </p>,
                      <p>
                          Chacun des nouveaux outils ou fonctionnalités qui sont ajoutés à la présente boutique est également assujetti aux Conditions d'utilisation. Vous pouvez consulter la version la plus récente des Conditions d'utilisation à tout moment sur cette page. Nous nous réservons le droit de mettre à jour, modifier ou remplacer n'importe quelle partie des présentes Conditions d'utilisation en publiant lesdites mises à jour et/ou modifications sur notre site web. Il vous incombe de vérifier cette page de temps à autre pour voir si des changements y ont été apportés. En continuant à accéder au site web ou à l'utiliser après la publication des modifications, vous acceptez celles-ci.<br/>
                          Notre boutique est hébergée sur Shopify Inc. Cette société nous fournit la plateforme e-commerce en ligne qui nous permet de vous vendre nos produits et services.
                      </p>
                  ]
              },
              {
                title:<h3>SECTION 1 – CONDITIONS D'UTILISATION DE LA BOUTIQUE EN LIGNE</h3>,
                paragraph:[
                    <p>En acceptant les présentes Conditions d'utilisation, vous déclarez avoir atteint ou dépassé l'âge de la majorité dans votre région, province ou État et nous avoir donné l'autorisation de permettre à toute personne mineure à votre charge d'utiliser ce site.</p>,
                    <p>Vous ne devez en aucune façon utiliser nos produits à des fins illégales ou non autorisées, ni violer des lois de votre juridiction lorsque vous utilisez le Service (y compris, sans toutefois s'y limiter, les lois relatives aux droits d'auteur).</p>,
                    <p>Vous ne devez pas transmettre de vers informatique, de virus ou tout code de nature destructrice.</p>,
                    <p>Une infraction ou une violation de n'importe laquelle des Conditions entraînera la résiliation immédiate de vos Services.</p>,

                ]
            },
            {
                title:<h3>SECTION 2 – CONDITIONS GÉNÉRALES</h3>,
                paragraph:[
                    <p>Nous nous réservons le droit de refuser de servir quelqu'un à tout moment et pour quelque raison que ce soit.</p>,
                    <p>Vous comprenez que votre contenu (à l'exception des informations relatives à votre carte de crédit) peut être transféré sans chiffrement et que cela comprend (a) des transmissions sur plusieurs réseaux ; et (b) des changements effectués dans le but de se conformer et de s'adapter aux exigences techniques de la connexion de réseaux ou d'appareils. Les informations de votre carte de crédit sont toujours chiffrées lors de leur transfert sur les réseaux.</p>,
                    <p>Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter toute partie du Service, toute utilisation du Service ou tout accès au Service, ou encore tout contact sur le site web à travers lequel le Service est fourni, sans notre autorisation écrite expresse.</p>,
                    <p>Les titres utilisés dans le présent accord sont inclus à titre indicatif uniquement et ne limiteront ni n'affecteront aucunement ces Conditions.</p>,

                ]
            },
            {
                title:<h3>SECTION 3 – EXACTITUDE, EXHAUSTIVITÉ ET ACTUALITÉ DES INFORMATIONS</h3>,
                paragraph:[
                   <p>Nous ne saurions être tenus responsables si les informations proposées sur ce site sont inexactes, incomplètes ou caduques. Le contenu de ce site est fourni à titre d'information générale uniquement et ne doit pas être considéré ou utilisé comme seule base pour la prise de décisions sans consulter des sources d'information plus importantes, plus exactes, plus complètes ou plus actuelles. Si vous vous fiez au contenu de ce site, vous le faites à vos propres risques.</p>,
                   <p>Ce site peut contenir certaines données historiques. Par définition, les données historiques ne sont pas actuelles et sont fournies uniquement à titre de référence. Nous nous réservons le droit de modifier les contenus de ce site à tout moment, mais nous n'avons aucune obligation de mettre à jour les informations qu'il contient, quelles qu'elles soient. Vous reconnaissez qu'il vous incombe de surveiller les changements apportés à notre site.</p>,
                ]
            },
            {
                title:<h3>SECTION 4 – MODIFICATIONS DU SERVICE ET DES PRIX</h3>,
                paragraph:[
                    <p>Les prix de nos produits sont modifiables sans préavis.</p>,
                    <p>Nous nous réservons le droit de modifier ou de mettre fin au Service (ou à une quelconque partie de celui-ci) à tout moment et sans préavis.</p>,
                    <p>Nous ne pourrons être tenus responsables envers vous ou tout tiers de tout changement de prix, ou encore de toute modification, suspension ou interruption du Service.</p>,
                ]
            },
            {
                title:<h3>SECTION 5 – PRODUITS OU SERVICES (le cas échéant)</h3>,
                paragraph:[
                    <p>Il est possible que certains produits ou services ne soient disponibles qu'en ligne à travers le site web. Il se peut que les quantités de ces produits ou services soient limitées et que leur retour ou leur échange soit strictement assujetti à notre Politique de retour.</p>,
                    <p>Nous nous sommes efforcés de présenter aussi précisément que possible les couleurs et images des produits figurant sur la boutique. Nous ne pouvons cependant pas garantir la précision d'affichage des couleurs sur l'écran de votre ordinateur.
                    Nous nous réservons le droit, sans toutefois y être obligés, de limiter la vente de nos produits ou Services à n'importe quelle personne, région géographique ou juridiction donnée. Nous nous autorisons à exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités des produits ou services que nous offrons. Toutes les descriptions des produits et leur tarification sont modifiables à tout moment, sans préavis et à notre entière discrétion. Nous nous réservons le droit d'interrompre la vente d'un produit à tout moment. Toute offre de produit ou service sur ce site est nulle là où la loi l'interdit.</p>,
                    <p>Nous ne garantissons pas que la qualité des produits, services, informations ou autres matériels que vous achetez ou que vous vous procurez répondra à vos attentes ni que les erreurs que comporte éventuellement le Service seront corrigées.</p>,
                ]
            },
            {
                title:<h3>SECTION 6 – EXACTITUDE DE LA FACTURATION ET DES INFORMATIONS DE COMPTE</h3>,
                paragraph:[
                    <p>
                        Nous nous réservons le droit de refuser toute commande que vous passez auprès de nous. Nous pouvons, à notre seule discrétion, limiter ou annuler les quantités achetées par personne, par foyer ou par commande. Ces restrictions peuvent inclure les commandes passées par ou sur le même compte client, la même carte de crédit et/ou des commandes utilisant la même adresse de facturation et/ou d'expédition. Si nous modifions ou annulons une commande, il se peut que nous tentions de vous en aviser en vous contactant au moyen de l'adresse e-mail et/ou de l'adresse de facturation ou du numéro de téléphone fournis au moment de la commande. Nous nous réservons le droit de limiter ou d'interdire les commandes qui, selon nous, semblent avoir été passées par des négociants, des revendeurs ou des distributeurs.<br/>
                        Vous acceptez de fournir des informations d'achat et de compte actuelles, complètes et exactes pour tous les achats effectués dans notre boutique. Vous acceptez de mettre rapidement à jour votre compte et toute autre information, y compris votre adresse e-mail et vos numéros de cartes de crédit et leurs dates d'expiration, afin que nous puissions finaliser vos transactions et vous contacter en cas de besoin.
                    </p>,
                    <p>Pour plus d'informations, veuillez consulter notre Politique de retour.</p>,
                ]
            },
            {
                title:<h3>SECTION 7 – OUTILS FACULTATIFS</h3>,
                paragraph:[
                    <p>Nous sommes susceptibles de vous fournir l'accès à des outils tiers que nous ne surveillons, ne contrôlons et ne gérons pas.</p>,
                    <p>Vous reconnaissez et acceptez que nous vous fournissons l'accès à ces outils « tels quels » et « sous réserve de disponibilité », sans garantie, représentation ou condition d'aucune sorte et sans la moindre approbation. Nous ne saurions être tenus responsables de quoi que ce soit à l'égard de ce qui pourrait résulter de ou être relié à votre utilisation des outils facultatifs tiers.</p>,
                    <p>Toute utilisation par vous des outils facultatifs proposés par le biais du site est entièrement à votre discrétion et à vos propres risques. En outre, il vous appartient de vous renseigner sur les conditions dans lesquelles ces outils sont fournis par le(s) fournisseur(s) tiers concerné(s) et accepter ces conditions.</p>,
                    <p>Il se peut également qu'à l'avenir, nous proposions de nouveaux services et/ou de nouvelles fonctionnalités à travers le site web (y compris le lancement de nouveaux outils et ressources). Ces nouveaux services et/ou fonctionnalités seront aussi assujettis aux présentes Conditions d'utilisation.</p>,
                ]
            },
            {
                title:<h3>ARTICLE 8 – LIENS DE TIERS</h3>,
                paragraph:[
                    <p>Certains contenus, produits et services accessibles via notre Service peuvent inclure des éléments provenant de tiers.</p>,
                    <p>Les liens de tiers sur ce site peuvent vous rediriger vers des sites web de tiers qui ne sont pas affiliés à nous. Nous ne sommes pas tenus d’examiner ou d’évaluer leur contenu ou leur exactitude, de même que nous ne garantissons pas et n’assumons aucune responsabilité quant aux contenus ou sites web, ou aux autres contenus, produits ou services de sources tierces.</p>,
                    <p>Nous ne sommes pas responsables des préjudices ou dommages liés à l’achat ou à l’utilisation de biens, services, ressources, contenus ou de toute autre transaction reliée à ces sites web tiers. Veuillez lire attentivement les politiques et pratiques de ces tiers et assurez-vous de bien les comprendre avant de vous engager dans une transaction. Les plaintes, réclamations, préoccupations ou questions concernant les produits de tiers doivent être adressées à ces mêmes tiers.</p>,

                ]
            },
            {
                title:<h3>ARTICLE 9 – COMMENTAIRES, RETOURS D'EXPÉRIENCE ET AUTRES SOUMISSIONS</h3>,
                paragraph:[
                    <p>
                        Si, à notre demande, vous soumettez des contenus spécifiques (par exemple, dans le cadre de votre participation à des concours), ou si, sans demande de notre part, vous envoyez des idées créatives, des suggestions, des propositions, des plans ou d’autres éléments, que ce soit en ligne, par e-mail, par courrier ou autrement (collectivement, « commentaires »), vous nous accordez le droit, à tout moment et sans restriction, de modifier, copier, publier, distribuer, traduire et utiliser dans quelque média que ce soit tous les commentaires que vous nous transmettez. Nous ne sommes pas et ne devrons en aucun cas être tenus (1) de maintenir la confidentialité des commentaires ; (2) de dédommager qui que ce soit pour tout commentaire fourni ; ou (3) de répondre aux commentaires.<br/>
                        7. Nous pouvons, mais nous n'en avons pas l'obligation, supprimer le contenu et les Comptes contenant du contenu que nous jugeons, à notre seule discrétion, illégal, offensant, menaçant, diffamatoire, pornographique, obscène ou autrement répréhensible ou qui viole la propriété intellectuelle d'une partie ou les présentes Conditions d'utilisation.
                        Vous convenez que vos commentaires ne doivent en aucun cas porter atteinte aux droits de tiers, y compris aux droits d'auteur, aux marques de commerce, à la vie privée, à la personnalité ou à tout autre droit personnel ou de propriété intellectuelle. Vous convenez en outre que vos commentaires ne devront contenir aucun élément illégal, injurieux ou obscène, ni aucun virus informatique ou autre logiciel malveillant susceptible d'affecter d'une quelconque façon le fonctionnement du Service ou de tout site web connexe. Vous ne pouvez pas utiliser de fausse adresse e-mail, prétendre être quelqu’un que vous n’êtes pas, ou essayer de nous induire, nous ou les tiers, en erreur quant à l’origine des commentaires. Vous êtes entièrement responsable de tous les commentaires que vous émettez ainsi que de leur exactitude. Nous déclinons toute responsabilité à l'égard des commentaires publiés par vous ou un tiers.
                    </p>                    
                ]
            },
            {
                title:<h3>ARTICLE 10 – INFORMATIONS PERSONNELLES</h3>,
                paragraph:[
                    <p>
                        La transmission de vos informations personnelles sur notre boutique est régie par notre Politique de confidentialité.  Cliquez ici pour consulter notre Politique de Confidentialité.
                    </p>                    
                ]
            },
            {
                title:<h3>ARTICLE 11 – ERREURS, INEXACTITUDES ET OMISSIONS</h3>,
                paragraph:[
                    <p>Il se peut qu'il y ait parfois, sur notre site ou dans le Service, des informations contenant des erreurs typographiques, des inexactitudes ou des omissions reliées aux descriptions, aux prix, aux promotions, aux offres, aux frais d’expédition, aux délais d'acheminement et à la disponibilité des produits. Nous nous réservons le droit de corriger toute erreur, inexactitude ou omission, et de changer ou d'actualiser des informations, voire d’annuler des commandes si une quelconque information dans le Service ou sur tout site web connexe est inexacte, et ce, à tout moment et sans préavis (y compris après que vous ayez passé votre commande).</p>,
                    <p>Nous ne sommes pas tenus d'actualiser, de modifier ou de clarifier les informations indiquées dans le Service ou sur tout site web connexe, y compris mais sans s'y limiter, les informations sur les prix, sauf si la loi l'exige. Aucune date précise de mise à jour ou d’actualisation appliquée au Service ou à tout site web connexe ne saurait être définie pour indiquer que l'ensemble des informations offertes dans le Service ou sur tout site web connexe ont été modifiées ou mises à jour.</p>,                  
                ]
            },
            {
                title:<h3>ARTICLE 12 – UTILISATIONS INTERDITES</h3>,
                paragraph:[
                    <p>En plus des autres interdictions énoncées dans les Conditions d’utilisation, il vous est interdit d’utiliser le site ou son contenu :
                    (a) à des fins illégales ; (b) pour inciter des tiers à réaliser des actes illégaux ou à y prendre part ; (c) pour enfreindre toute ordonnance locale ou toute réglementation, règle ou loi internationale, fédérale, provinciale ou étatique ; (d) pour transgresser ou violer nos droits de propriété intellectuelle ou ceux de tiers ; (e) pour harceler, maltraiter, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer quiconque en fonction du sexe, de l’orientation sexuelle, de la religion, de l’origine ethnique, de la race, de l’âge, de l’origine nationale ou d’un handicap ; (f) pour soumettre des renseignements faux ou trompeurs ;
                    (g) pour mettre en ligne ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourrait être utilisé en vue de compromettre la fonctionnalité ou le fonctionnement du Service ou de tout site web connexe, ainsi que d'autres sites web ou d’Internet ; (h) pour recueillir ou suivre les renseignements personnels d’autrui ; (i) pour spammer, hameçonner, détourner un domaine, extorquer des informations, parcourir, explorer ou balayer le web ; (j) à des fins obscènes ou immorales ; ou (k) pour perturber ou contourner les mesures de sécurité du Service ou de tout site connexe, ainsi que d'autres sites web ou d’Internet. Nous nous réservons le droit de mettre fin à votre utilisation du Service ou de tout site web connexe pour avoir enfreint les interdits en matière d'utilisation.</p>,
                ]
            },
            {
                title:<h3>ARTICLE 13 – EXCLUSION DE GARANTIES ET LIMITATION DE RESPONSABILITÉ</h3>,
                paragraph:[
                    <p>Nous ne garantissons, certifions ou déclarons en aucun cas que votre utilisation de notre Service sera ininterrompue, sécurisée, sans délai ou sans erreur.</p>,
                    <p>Nous ne garantissons pas que les résultats qui pourraient être obtenus en utilisant le Service seront exacts ou fiables.</p>,
                    <p>
                        Vous acceptez que, de temps à autre, nous puissions retirer le Service pour des périodes indéterminées ou l'annuler à tout moment et sans préavis.<br/>
                        Vous convenez expressément que votre utilisation du Service, ou votre incapacité à utiliser celui-ci, est à votre seul risque. Le Service ainsi que tous les produits et services qui vous sont fournis par le biais de celui-ci sont (sauf mention expresse de notre part) fournis « tels quels » et « sous réserve de disponibilité » pour votre utilisation, et ce, sans représentation, garanties ou conditions d'aucune sorte, soit expresses soit implicites, y compris toutes les garanties ou conditions implicites de commercialisation ou de qualité marchande, d’adaptation à un usage particulier, de durabilité, de titre et d’absence de contrefaçon.
                        Le Bureau, nos directeurs, responsables, employés, sociétés affiliées, agents, contractants, stagiaires, fournisseurs, prestataires de services et concédants ne peuvent en aucun cas être tenus responsables de toute blessure, perte, réclamation, ou de quelconques dommages directs, indirects, accessoires, punitifs, spéciaux ou consécutifs, y compris mais sans s'y limiter, de la perte de profits, revenus, économies ou données, de coûts de remplacement ou autres dommages similaires, qu’ils soient contractuels, délictuels (même en cas de négligence), de responsabilité stricte ou autre, résultant de votre utilisation du Service ou de tout service ou produit recourant à celui-ci, ou de toute autre réclamation liée de quelque manière que ce soit à votre utilisation du Service ou de tout produit, y compris mais sans s'y limiter, à des erreurs ou omissions dans un contenu, ou à de quelconques pertes ou dommages découlant de l’utilisation du Service ou d'un contenu (ou produit) publié, transmis ou rendu accessible par le biais du Service, et ce, même si vous avez été averti(e) de la possibilité qu’ils surviennent.
                        Du fait que certains États ou juridictions ne permettent pas d’exclure ou de limiter la responsabilité quant aux dommages consécutifs ou accessoires, notre responsabilité dans ces États ou juridictions sera limitée dans la mesure maximale permise par la loi.
                    </p>
                ]   
            },
            {
                title:<h3>ARTICLE 14 – INDEMNISATION</h3>,
                paragraph:[
                    <p>Vous acceptez d’indemniser, de défendre et de tenir Le Bureau et notre société mère, nos filiales, sociétés affiliées, partenaires, responsables, directeurs, agents, contractants, concédants, prestataires de services, sous-traitants, fournisseurs, stagiaires et employés, quittes de toute réclamation ou demande, y compris d'honoraires raisonnables d’avocat, émise par un quelconque tiers à cause de ou consécutivement à votre violation des présentes Conditions d’utilisation ou des documents auxquels elles font référence, ou à votre violation de quelconques lois ou droits d’un tiers.</p>,
                ]   
            },
            {
                title:<h3>ARTICLE 15 – DISSOCIABILITÉ</h3>,
                paragraph:[
                    <p>Dans le cas où une quelconque disposition des présentes Conditions d’utilisation est jugée illégale, nulle ou inapplicable, cette disposition sera néanmoins applicable dans la pleine mesure permise par la loi, et la partie non applicable sera considérée comme étant dissociée de ces Conditions d’utilisation, sans que ce jugement n'affecte la validité et l’applicabilité des autres dispositions.</p>
                ]   
            },
            {
                title:<h3>ARTICLE 16 – RÉSILIATION</h3>,
                paragraph:[
                    <p>Les obligations et responsabilités engagées par les parties avant la date de résiliation resteront en vigueur après la résiliation de cet accord, et ce, à toutes fins.</p>,
                    <p>
                        Les présentes Conditions d’utilisation resteront en vigueur, à moins et jusqu’à ce qu’elles soient résiliées par vous ou par nous. Vous pouvez résilier ces Conditions d’utilisation à tout moment en nous avisant que vous ne souhaitez plus utiliser nos Services, ou lorsque vous cessez d’utiliser notre site.<br/>
                        Si nous jugeons ou suspectons, à notre seule discrétion, que vous ne respectez pas ou que vous n'avez pas respecté une quelconque modalité ou disposition des présentes Conditions d’utilisation, nous pouvons également résilier cet accord à tout moment et sans préavis. Vous demeurerez alors responsable de toutes les sommes redevables jusqu’à la date de résiliation (incluse), en conséquence de quoi nous pouvons vous refuser l’accès à nos Services (ou à une partie de ceux-ci).
                    </p>,
                ]   
            },
            {
                title:<h3>ARTICLE 17 – INTÉGRALITÉ DE L’ACCORD</h3>,
                paragraph:[
                    <p>Tout manquement de notre part à l’exercice ou à l’application d'un droit ou d'une disposition des présentes Conditions d’utilisation ne constitue pas une renonciation à ce droit ou à cette disposition.</p>,
                    <p>
                        Les présentes Conditions d’utilisation ou toute autre politique ou règle d’exploitation que nous publions sur ce site ou qui concernent le Service constituent l’intégralité de l’entente et de l’accord entre vous et nous, et régissent votre utilisation du Service. Elles remplacent l'ensemble des accords, communications et propositions antérieurs et actuels, oraux ou écrits, entre vous et nous (y compris, mais sans s'y limiter, toute version antérieure des Conditions d’utilisation).<br/>
                        Toute ambiguïté quant à l’interprétation de ces Conditions d’utilisation ne doit pas être interprétée en défaveur de la partie rédactrice.
                    </p>,
                ]   
            },
            {
                title:<h3>ARTICLE 18 – LOI APPLICABLE</h3>,
                paragraph:[
                    <p>Les présentes Conditions d’utilisation, ainsi que tout accord distinct par lequel nous vous fournissons les Services sont régis et interprétés en vertu des lois de 14 avenue Maréchal Juin , Marseille, Corse, 13004, France.</p>
                ]   
            },
            {
                title:<h3>ARTICLE 19 – MODIFICATIONS APPORTÉES AUX CONDITIONS D’UTILISATION</h3>,
                paragraph:[
                    <p>Vous pouvez consulter la version la plus récente des Conditions d’utilisation à tout moment sur cette page.</p>,
                    <p>Nous nous réservons le droit, à notre seule discrétion, de mettre à jour, modifier ou remplacer toute partie des présentes Conditions d'utilisation en publiant lesdites mises à jour et/ou modifications sur notre site web. Il vous incombe de vérifier notre site web de temps à autre pour voir si des changements y ont été apportés. En continuant à accéder à notre site web et au Service ou à les utiliser après la publication de modifications apportées aux présentes Conditions d'utilisation, vous acceptez celles-ci.
                    </p>,
                ]   
            },
            {
                title:<h3>ARTICLE 20 – COORDONNÉES</h3>,
                paragraph:[
                    <p>Les questions relatives aux Conditions d’utilisation doivent nous être envoyées à marine.jacquens@gmail.com.</p>,
                ]   
            },
              
          ]

      },

      {
          title:"Conditions générales d'utilisation",
          content:[
              {
                  title:<h3>Définitions</h3>,
                  paragraph:[
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ", 
                      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                  ]
              },
              
          ]

      },

  ]

  let getModalNumber = (props) => {

    this.handleChangeModalNumber(props)

  }


    return (
      <div id="home">
        <div className="header">
          <Home/>
        </div>
        <div>
          <Services/>
          <Responsive/>
          <Skills/>
          <Portfolio/>
          <Prices/>
          <Footer/>
          <MandatoryInfoLinks onClick={getModalNumber}/>
          <ButtonUp/>
          <MandatoryInfoModal open={this.state.open} handleClose={()=>this.handleCloseMandatoryModal()} modal={legalInfoModal} modalIndex={this.state.modalIndex}/>
        </div>
      </div>
    );
  }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<ServicesWebSite />);