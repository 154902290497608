import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {MdOutlineClose} from 'react-icons/md';


function MandatoryInformationModal(props){
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 600,
        minWidth:250,
        maxHeight:500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p:4,
        overflowY:'scroll',
        textAlign:'justify',
      };
      

    return(

        <div className="mandatoryInfoModal">

             <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >   

                <Box sx={style}>
                    

                        <div className="mandatoryInfoModal">

                                    <div className="closeModalBtn">
                                        <button onClick={()=>props.handleClose()}><MdOutlineClose/></button>
                                    </div>

                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {props.modal[props.modalIndex].title}
                                    </Typography>

                                    <div className="hrMandatoryInfoModal"><p></p></div>
                               
                    
                           


                            {props.modal[props.modalIndex].content.map((item,i)=>(
                                
                                <div>
                                    <Typography id="modal-modal-title" variant="h6" component="h3" mt={2}>
                                        {item.title}
                                    </Typography>

                                    {item.paragraph.map((sentence,index)=>(

                                        <Typography variant="body2" gutterBottom mt={2}>
                                            {sentence}
                                        </Typography>

                                    ))}

         
                                    
                                </div>


                            
                            ))}

                            <div className="finalCloseModalBtn">
                                <button onClick={()=>props.handleClose()}>Fermer</button>
                            </div>
                                
                            

                            

                            

                        </div>

                                              
                </Box>

               
      
            </Modal> 

            

            
        </div>
    )
}

export default MandatoryInformationModal;