import React from 'react';
import computer from '../assets/ressources/computer.png';


class Responsive extends React.Component {

    render(){

        return(
            <div className="responsive_banner">

                <div className="banner">

                    <div className="banner_title">
                        <h2>Développement Web Responsive</h2>
                        <h3>Compatible P.C, Laptop, Tablette, Mobile</h3>
                    </div>
                    
                    <img className="computer" src={computer} alt="computer"/>


                </div>
                
                    


            </div>
            
        )
    }

        
    
}

export default Responsive;