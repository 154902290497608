import React, { useState, useEffect } from 'react';

// REACT ICONS 
import {BiPlusMedical} from 'react-icons/bi';
import{BsFillArrowDownCircleFill} from 'react-icons/bs';


function PricesCard(props){

    const [showList, setShowList] = useState(false);
    const [listIndex, setListIndex] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    
    function handleMouseEnter(index){
        setMouseEnter(true)
        setListIndex(index)

    }

    function handleMouseLeave(index){
        setMouseEnter(false)
        setListIndex(index)

        if(dimensions.width < 1050){
            setShowList(false)
        }

    }

    function handleListTasks(index){
        setShowList(true)
        setListIndex(index)
        // console.log(props)
    }

   function handleNoTasks(index){
        setShowList(false)
    }

    const style1 = {

        taskUnevenLine :{
            backgroundColor:"rgba(126, 126, 126, 0.2)",
            fontWeight:"bold",

        },
        quoteButton:{
          display:'none',
        }, 
        listHeight:{
            height:'unset',
            minHeight:'fitContent',
        }



    }

  

    

    useEffect(() => {

      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })

      }
  
      window.addEventListener('resize', handleResize)
  
      return _ => {
        window.removeEventListener('resize', handleResize)
      
      }

    },[dimensions])

    return(

     
            <section className={props.sectionName} onMouseEnter={()=> handleMouseEnter(props.index)} onMouseLeave={()=> handleMouseLeave(props.index)} style={dimensions.width > 1050 && showList === true ? style1.listHeight : null}>
                
                <div className="miniCard">
                    
                    <h5>{props.title}</h5>
                    <div className="delivery_hr"><p></p></div>
                    <p>À PARTIR DE</p>
                    {mouseEnter  === true &&  listIndex === props.index ?
                        
                        <div className="priceTag">

                            <p style={{color:"white"}} className="price">{props.price}</p>
                            <p style={{color:"white"}}>€*{props.subscription}</p>
                                                    
                        </div>

                    : 
                        <div className="priceTag">

                            <p style={{color:"black"}} className="price">{props.price}</p>
                            <p style={{color:"black"}}>€*{props.subscription}</p>
                                                    
                        </div>

                    }

                    <div className="delivery_hr"><p></p></div>    
                    <p>{props.summary}</p>
                    
                    {mouseEnter  === true && listIndex === props.index ?

                       <p style={{color:"white"}} className="summaryDelivery">{props.stack}</p>
                    
                    :
                        
                        <p style={{color:"black"}} className="summaryDelivery">{props.stack}</p>

                    }

                </div>

                <div className="tasksListBlock">

                    {showList === true &&  listIndex === props.index ? 
                        
                        props.tasks.map((tasks,index)=>(
                            
                            <div className="tasksList" style={index%2 === 0 ? style1.taskUnevenLine : null}>
                                <BiPlusMedical size={15} className="iconPlusTasks"/>
                                <p>{tasks}</p>
                            </div>
                                                
                                    
                        ))
                    
                    : null}

                </div>                       
                
                <BsFillArrowDownCircleFill className="seeMoreButtonPrices" size={30} style={ showList === true  ? style1.quoteButton : null} onClick={()=> handleListTasks(props.index)}/>
                <a href="#quotes" className="quoteButton" onClick={()=>props.onClick()} style={ showList === false  ? style1.quoteButton : null}>DEMANDER UN DEVIS</a>


            </section>
                                         

    )
}

export default PricesCard