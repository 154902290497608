import React, { useState, useEffect } from 'react';

// COMPONENTS
import MandatoryInfoModal from '../components/mandatoryInformationModal';

// REACT ICONS
import {FaLinkedinIn, FaInstagram, FaGithub, FaPhoneAlt, FaEnvelopeOpen, FaFileDownload} from 'react-icons/fa';

function Footer(){

    return(
        <div>
            <div className="footer">

                <div className="footerIntro">
                    <div className="footerBrand">
                        {/* <p>Logo</p> */}
                        <a href="https://marine-jacquens.students-laplateforme.io/" target="_blank">DERNIER PROJET MIS EN LIGNE</a>
                    </div>
                    <div className="footerSocialNetworkIcons">
                        <a href="https://www.linkedin.com/in/marine-jacquens/"><FaLinkedinIn size={25}/></a>
                        <a href="https://www.instagram.com/post_it_code/"><FaInstagram size={25}/></a>
                        <a href="https://github.com/marine-jacquens?tab=repositories"><FaGithub size={23}/></a>
                    </div>
                </div>
                <div className="footerSecondLine">
                    <div className="footerSummary">
                        <h4>DÉVELOPPEMENT WEB, MOBILE & STRATÉGIE DIGITALE</h4>
                        <p>
                            Diplômée d'école de commerce en marketing digital, 
                            j'ai développé une forte appétence pour la programmation web suite à une initiation. 
                            Après l'obtention des certifications 
                            <a href="https://www.francecompetences.fr/recherche/rncp/31114/" target="_blank"> Développeur web et web mobile </a>
                            ainsi que 
                            <a href="https://www.francecompetences.fr/recherche/rncp/31678/" target="_blank"> Concepteur développeur d'applications</a>, 
                            je vous propose aujourd'hui mes services afin de concevoir avec 
                            vous vos projets de site et application web ou mobile. 

                        </p>  
                            
                        <br/>
                        <p>
                            Pour obtenir des renseignements complémentaires avant 
                            une demande de devis, contactez moi par mail ou par téléphone. 
                            Si vous souhaitez simplement en apprendre plus sur mon parcours vous avez 
                            la possibilité de consulter mon 
                            <a href={require("../assets/ressources/CV_Développeur_Front_Marine_Jacquens.png")} target="_blank"> curriculum vitæ</a>.
                        </p>
                    </div>
                    <div className="footerContact">
                        <h4>MARINE JACQUENS</h4>
                        <div className="footerContactIcons">
                            <FaPhoneAlt size={15}/>
                            <p>+33 6 46 11 35 65</p>
                        </div>
                        <div className="footerContactIcons">
                            <FaEnvelopeOpen size={15}/>
                            <a href="mailto:contact.mjacquens@gmail.com">contact.mjacquens@gmail.com</a>
                        </div>
                        <div className="CVLink">
                            <a href={require("../assets/ressources/CV_Développeur_Front_Marine_Jacquens.png")} target="_blank">                    
                                <FaFileDownload size={20}/>
                            </a>
                            <a href={require("../assets/ressources/CV_Développeur_Front_Marine_Jacquens.png")} target="_blank">                    
                                <img src={require("../assets/ressources/CV_Développeur_Front_Marine_Jacquens.png")} alt="CV"/>
                                <figcaption>CV</figcaption>
                            </a>
                        </div>
                    </div>
                    
                    
                </div>
                
                
            </div>

        </div>
    )
}

export default Footer