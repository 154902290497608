import React from 'react';

// COMPONENT
import Navbar from '../components/home/navbar';
import Contact from '../components/home/contact';

// REACT ICONS
import {BiChevronsDown} from 'react-icons/bi';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter:0, 
            totalJob:-1,
        };
      }
    
      componentDidMount() {

        this.timerID = setInterval(
          () => this.jobAnimation(),
          8000
        );
      }

      componentWillUnmount() {
        clearInterval(this.timerID);
      }

      jobAnimation(){

        if(this.state.counter < 2){
    
                this.setState({
                    counter : this.state.counter + 1
                });
    
        }else{
            this.setState({
                    counter : 0
                });
        }

    }

      
      
    render() {

        const job = [
        
            {
                title:'DÉVELOPPEMENT WEB',
            },
            {
                title:'CONCEPTION D\'APPLICATION',
            },
            {
                title:'STRATÉGIE DIGITALE',
            },
    
        ]

        // job.map((item)=>(
        //     this.setState({totalJob : this.state.totalJob + 1})
        // ))
  
        return (
    
            <div>
                <Contact/>
                <Navbar/>
                <div className="button_next" >
                            
                    <h1 className="animationTitle">
                        {job[this.state.counter].title} 
                   </h1>

                    
                    <a href="#services" className="button_behavior"><BiChevronsDown size={25}/></a>
                </div>
            </div>
            
            
    
        );
      }
}

export default Home