import React, { useState, useEffect } from 'react';

import {FaChevronUp} from 'react-icons/fa';



function ButtonUp(props){

    const [buttonUpDisplay,setButtonUpDisplay] = useState('false');


    useEffect(() => {
        function handleResize() {
            
          if(window.scrollY > 100){
            setButtonUpDisplay('true')  
          }else{
            setButtonUpDisplay('false') 
          }
        
    }
    
        window.addEventListener('scroll', handleResize)
      })

    return(
        <div>

            {buttonUpDisplay === 'true' ?  

                <div className="button_up">
                    <a href="#home"><FaChevronUp size={20}/></a>
                </div>

            : null }

        </div>
       
    )
}

export default ButtonUp