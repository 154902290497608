import React from 'react';

// LIBRAIRIE REACT ICON
import {BiPlus} from 'react-icons/bi';
import {MdOutlineClose} from 'react-icons/md';
import {FcCalendar} from 'react-icons/fc';
import {FaTags,FaChevronLeft,FaChevronRight} from 'react-icons/fa';
import {IoMdEye} from 'react-icons/io';




class Portfolio extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            button:0,
            showSectionButtons:
                [
                    {
                        title:'PROJETS MIS EN LIGNE',
                   },
                   { 
                        title:'PROJETS EN COURS',
                    },
                    {
                        title:'TOUS LES PROJETS',
                    }

                ],  
            modalWindow : null, 
            displayModalWindow : false,

            
        };


    }

    handleButtonSection(props){
    
        this.setState({button:props})
    }

    handleClick(props){
        this.setState({modalWindow:props})
        this.setState({displayModalWindow:true})

    }

    handleClickClose(){
        this.setState({displayModalWindow:false})
    }

    handleClickPrevious(){
        this.setState({modalWindow:this.state.modalWindow - 1})
    }

    handleClickNext(){
        this.setState({modalWindow:this.state.modalWindow + 1})
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));


    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));


    }

    handleScroll(){
        if(window.scrollY < 610){
            this.setState({displayModalWindow:false})
        }
    }




    

    


    render(){

        const style1 = {
            color : "white", 
            backgroundColor: "#86C6D2",

        }

        const projects = [

            {
                title:"LES GOURMANDS GOURMETS",
                mainPicture:require("../assets/ressources/lesgourmandsgourmets1.png"),
                gallery:[
                    require("../assets/ressources/lesgourmandsgourmets2.png"),
                    require("../assets/ressources/lesgourmandsgourmets3.png"),
                    require("../assets/ressources/lesgourmandsgourmets4.png"),
                ],
                type:"DÉVELOPPEMENT WEB - BLOG",
                category:"Développement web",
                techno : "Wordpress", 
                stack : [
                    "Wordpress", "YoastSEO", "MonsterInsights", "All-in-One WP migration"
                ],
                date : "2022", 
                online : true,
                websiteLink : "https://lesgourmandsgourmets.com/",
                summary: "Les Gourmands Gourmets est un blog spécialisé dans la critique culinaire. Les articles sont classés par catégories d'établissement culinaire.",
                tasks:[
                    "Choix d'un template",
                    "Création des catégories",
                    "Mise en place des widgets",
                    "Création de liens avec les RS",
                    "Mise en place d'un formulaire Newsletter",
                    "Rédaction d'article",
                    "Redimensionnement des images et ajout en galerie", 
                    "Mise en place des plug-ins (Optimisation du référencement, Migration et lien Google Analytics)",
                    "Optimisation du référencement des articles et catégories",
                    "Hébergement web",
                    "Mise en ligne"
                ], 
            },
            {
                title:"GIANNOTTI & FILS",
                mainPicture:require("../assets/ressources/giannottietfils1.png"),
                gallery:[
                    require("../assets/ressources/giannottietfils2.png"),
                    require("../assets/ressources/giannottietfils3.png"),
                    require("../assets/ressources/giannottietfils4.png"),
                    require("../assets/ressources/giannottietfils5.png"),
                    require("../assets/ressources/giannottietfils6.png"),
                    require("../assets/ressources/giannottietfils7.png"),
                ],
                type:"DÉVELOPPEMENT WEB - E-COMMERCE",
                category:"Développement web",
                techno : "React.js - Strapi",
                stack : [
                    "React.js", "Strapi"
                ],
                date : "2022", 
                online : false,
                websiteLink : null,
                summary: "Giannotti & Fils est une belle et authentique quincaillerie située à Propriano.Le magasin met également en vente des ustensiles, de la vaisselle et de la décoration d'intérieur. Le site web consiste à mettre en ligne leur catalogue produits. Les gérants auront la possibilité d'administrer l'interface eux-même en modifiant les produits (images, descriptifs). ", 
                tasks:[
                    "Wireframe",
                    "Zoning", 
                    "Prototype",
                    "Mise en place structure",
                    "Stylisation des pages",
                    "Mise en place interface d'administration",
                    "Mise en place des requêtes entre client/serveur",  
                    "Hébergement Web",
                    "Mise en ligne"
                ], 

            },
            {
                title:"PORTFOLIO D'ÉTUDE",
                mainPicture:require("../assets/ressources/portfolio1.png"),
                gallery:[
                    require("../assets/ressources/portfolio2.png"),
                    require("../assets/ressources/portfolio3.png"),
                    require("../assets/ressources/portfolio4.png"),
                    require("../assets/ressources/portfolio5.png"),
                    require("../assets/ressources/portfolio6.png"),

                ],
                type:"DÉVELOPPEMENT WEB - PORTFOLIO",
                category:"Développement web",
                techno : "HTML - CSS ",
                stack : [
                    "HTML", "CSS", "Javascript", "Jquery", "PHP", "SQL", "POO", "Gravit designer"
                ],
                date : "2020", 
                online : true,
                websiteLink : "https://marine-jacquens.students-laplateforme.io/",
                summary: "Ce portfolio réuni un échantillon des projets les plus élaborés réalisés au cours de ma première année de formation au développement web. Vous pouvez naviguer au sein de chaque projet, vous inscrire et vous connecter afin d'avoir un accès complet aux différentes interfaces.", 
                tasks:[
                    "Création de visuels",
                    "Mise en place structure",
                    "Stylisation des pages",
                    "Mise en ligne", 

                ], 

            },
            

        ]

        let project

        if(this.state.button === 0){
 
            project = projects.map((card,i)=>(

                card.online === true  ?

                    <ProjectCard title={card.title} picture={card.mainPicture} type={card.type} techno={card.techno}  index={i} onClick={() => this.handleClick(i)} modal={this.state.displayModalWindow}/>

                :
                null
                
            ))
                
        }else if(this.state.button === 1){

            project = projects.map((card,i)=>(

                card.online === false  ?

                    <ProjectCard title={card.title} picture={card.mainPicture} type={card.type} techno={card.techno}  index={i} onClick={() => this.handleClick(i)} modal={this.state.displayModalWindow}/>
                :
                null
                
            ))

        }else{

            project = projects.map((card,i)=>(

                <ProjectCard title={card.title} picture={card.mainPicture} type={card.type} techno={card.techno} index={i} onClick={() => this.handleClick(i)} modal={this.state.displayModalWindow}/>

            ))
        }

        let projectsNumber = 0

        projects.map((item)=>(
            projectsNumber += 1
        ))

        let projectModalWindow

        if(this.state.displayModalWindow === true){

            projectModalWindow = projects.map((projectModal,i)=>(

                this.state.modalWindow === i ?

                   <ProjectWindow 
                   index={i}
                   title={projectModal.title} 
                   picture={projectModal.gallery}
                   category={projectModal.category}
                   stack={projectModal.stack}
                   date={projectModal.date}
                   websiteLink={projectModal.websiteLink}
                   summary={projectModal.summary}
                   tasks={projectModal.tasks}
                   totalProjects = {projectsNumber}
                   display = {this.state.displayModalWindow}
                   onClickClose={() => this.handleClickClose()}
                   onClickPrevious={() => this.handleClickPrevious()}
                   onClickNext={() => this.handleClickNext()}

                   />
                : 

                null
            ))


        }


                                            

        return(
            <div id="portfolio" className="portfolio_section">

                <div className="sectionTitle">
                    <h2>MES PROJETS</h2>
                    <h3>Projets professionnels et personnels</h3>
                </div>
                
                
                <div className="hr"><p></p></div>

                <div className="portfolio_buttons">
                    {
                        this.state.showSectionButtons.map((button,i) =>(
                            <button onClick={()=>this.handleButtonSection(i)} style = {this.state.button === i ? style1 : null}>{button.title}</button>
                        ))
                    }
                </div>
                

                <div className="portfolio_projects">

                    {project}
                        
                </div>
                   
                {projectModalWindow}
                                            
                    
                    

                
                
            </div>
        )
    }
}


class ProjectCard extends React.Component{

    constructor(props){
        super(props);
        this.state={
            showOverlay:false,
        }
    }

    handleBoxToggle = () => this.setState({showOverlay:true});
    handleBoxToggleReverse = () => this.setState({showOverlay:false});

    display = () => {

            this.setState({showOverlay:false})
            this.props.onClick()
    }
    

    render(){
    

        const style1 = {
            backgroundColor:"#333",
            color: "white"
        }

        const style2 = {

            display:{
                display:"none"
            },

            overlayImage:{
                position:"relative", 
            },

            overlay:{
                position:"absolute",
                top:"0%",
                width:"100%",  
                display:"flex", 
                flexDirection:"row", 
                justifyContent:"center",
                alignItems:"center", 
                backgroundColor: 'rgba(0,0,0,0.3)',
                height:"100%",

            },
            
            
        }

        return(
            <a href="#modal"  index={this.props.index} className="portfolio_project" onClick={this.display} onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxToggleReverse}>

                <div className="portfolio_project_picture">

                    {
                        this.state.showOverlay === true ?

                        <div style={style2.overlayImage}>
                            <div>
                                <img src={this.props.picture} alt="project_picture"/>
                            </div>
                            <div style={style2.overlay}>
                                <BiPlus size={50} color="#86C6D2" className="iconPlus"/>
                            </div>
                        </div>

                        : 

                        <div style={style2.overlayImage}>
                            <div><img src={this.props.picture} alt="project_picture"/></div>
                            <div className="seeProject"><IoMdEye size={40}/></div>
                        </div>

                        

                    }

                </div>
                
                <div className="portfolio_project_title" style={this.state.showOverlay === true ? style1 : null}>

                    <h5>{this.props.title}</h5>
                    <div className="portfolio_hr"><p></p></div>
                    <h6>{this.props.type}</h6>
                    <p>Stack : {this.props.techno}</p>

                </div>

            </a>
        )
    }
}


class ProjectWindow extends React.Component{

    constructor(props){

        super(props);

        this.state={
            galleryIndex:0,
        }

    }

    render(){

        let linkWebsite = (props) => (props != null ? <a href={props} className="websiteLink" target="_blank">VISITER LE SITE</a> : <p className="websiteLink">INDISPONIBLE</p>)

        let projectRedirect 

        if(this.props.index === 0){

            projectRedirect = 
            <div className="otherProjectRedirect">
              <button className="nextButton" onClick={()=>this.props.onClickNext()}>SUIVANT</button>
            </div>

        }else if(this.props.index === this.props.totalProjects - 1){

            projectRedirect = 
            <div className="otherProjectRedirect">
                <button className="previousButton" onClick={()=>this.props.onClickPrevious()}>PRECEDENT</button>
            </div>

        }else{
            projectRedirect = 
            <div className="otherProjectRedirect">
                <button className="previousButton" onClick={()=>this.props.onClickPrevious()}>PRECEDENT</button>                            
                <button className="nextButton" onClick={()=>this.props.onClickNext()}>SUIVANT</button>
            </div>             
        }

        let picturesNumber = 0

        this.props.picture.map((item)=>(
            picturesNumber += 1
        ))

        //console.log(picturesNumber)

        let gallery


        if(this.state.galleryIndex === 0){

            gallery = 
            <div className="gallery">
                <button className="closedChevron"><FaChevronLeft size={25} /></button>
                <img src={this.props.picture[this.state.galleryIndex]} alt="project_picture" className="bannerProjectPicture" />
                <button className="chevron"><FaChevronRight size={25} onClick={()=>this.setState({galleryIndex:this.state.galleryIndex+1})}/></button>
            </div>


        }else if(this.state.galleryIndex === picturesNumber-1){
            gallery = 
            <div className="gallery">
                <button className="chevron"><FaChevronLeft size={25} onClick={()=>this.setState({galleryIndex:this.state.galleryIndex-1})}/></button>
                <img src={this.props.picture[this.state.galleryIndex]} alt="project_picture" className="bannerProjectPicture" />
                <button className="closedChevron"><FaChevronRight size={25}/></button>
            </div>
        }else{
            gallery = 
            <div className="gallery">
                <button className="chevron"><FaChevronLeft size={25} onClick={()=>this.setState({galleryIndex:this.state.galleryIndex-1})} /></button>
                <img src={this.props.picture[this.state.galleryIndex]} alt="project_picture" className="bannerProjectPicture" />
                <button className="chevron"><FaChevronRight size={25} onClick={()=>this.setState({galleryIndex:this.state.galleryIndex+1})}/></button>
            </div>


        }

        return(

            <div className="modalWindow">

                <div id="modal" className="modalContent">

                    <div className="modalHeader">
                        <div className="modalCloseButton"><button onClick={()=>this.props.onClickClose()}><MdOutlineClose size={15}/></button></div>
                        <div><h2>{this.props.title}</h2></div>
                    </div>
                    <div className="modaleBody">
                        <p className="hrModale"></p>
                        {gallery}
                        <p className="summaryProject">
                            {this.props.summary}
                        </p>
                        <div className="projectDetails">

                            <div className="projectStep">
                                <h3>MISSIONS</h3>
                                <div className="hrModale"></div>
                                <div className="taskListing">
                                    <ul>
                                        {this.props.tasks.map((task)=>(<li>{task}</li>))}
                                    </ul>
                                </div>
                            </div>
                            <div className="projectInfo">
                                <h3>INFO PROJET</h3>
                                <div className="hrModale"></div>
                                <div className="basicInfoProject">
                                    <div className="calendar">
                                        <FcCalendar size={25}/>
                                        <p> Mise en ligne : {this.props.date}</p>
                                    </div>
                                    <div className="projectCategory">
                                        <FaTags size={25} color="rgb(244 68 54)"/>
                                        <p>Catégorie : {this.props.category}</p>
                                    </div>
                                </div>
                                <div className="hrModale"></div>
                                <div className="stackTags">

                                    {this.props.stack.map((item,i)=>(

                                        <p index={i} className="stackTag">{item}</p>

                                    ))}

                                </div>
                                <div className="hrModale"></div>
                                <div className="redirection">

                                    {linkWebsite(this.props.websiteLink)}
                                    
                                </div>

                            </div>
                        </div>

                        {projectRedirect}
                            
                    </div>
            
                </div>
            </div>
        )
    }

}


export default Portfolio
