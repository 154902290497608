import * as yup from "yup";

const urlRules = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const mailRules = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const phoneNumberRules = /^\+(?:[0-9]?){6,14}[0-9]$/;

export const basicSchema = yup.object().shape({

    firstname : yup.string().min(2, 'Trop court!').max(50, 'Trop long!').required("Ce champ est requis"), 
    lastname : yup.string().min(2, 'Trop court!').max(50, 'Trop long!').required("Ce champ est requis"),
    email : yup.string().matches(mailRules,{message:"Entrez un mail valide"}).required("Ce champ est requis"), 
    contactType : yup.string().required("Sélectionnez la situation qui vous correspond"),
    firm : yup.string(),
    phoneNumber : yup.string().matches(phoneNumberRules,{message:"Entrez un numéro de téléphone valide"}),
    link: yup.string().matches(urlRules,{message:"Entrez une url valide"}),
    projectType: yup.string().required("Sélectionnez un type de projet"),
    message:yup.string().min(20,"Détaillez précisément votre projet pour un maximum d'efficacité").required("Ce champ est requis"),
    termsAndConditionsOfUse:yup.bool().oneOf([true], 'Veuillez lire les conditions d\'utilisation').required('Veuillez lire et accepter les conditions d\'utilisation'),
})