import React from 'react';

//COMPONENTS
import PricesForm from '../components/prices/prices_form'; 
import PricesCard from '../components/prices/prices_card'; 



class Prices extends React.Component {

    constructor(props) {

        super(props);
        this.state = { 
            showQuotesForm:false,
        };
    }

    handleClickOpen(){
        this.setState({showQuotesForm:true})
    }

    handleClickClose(){
        this.setState({showQuotesForm:false})
    }

    render(){

        const style1 = {
            quotesForm:{
                display:"none"

            }
        }


        const deliveriesType = [

            // {
            //     title :"LANDING PAGE",
            //     price:"640",
            //     subscription:"",
            //     summary:"Page unique de présentation d'un projet (lancement produit/service)",
            //     stack:"Interface personnalisée",
            //     tasks:[
            //         "1ère Consultation (1h) : définition du projet (GRATUIT)", 
            //         "2ème Consultation (1h) : propositions de design", 
            //         "Réalisation 2 à 3 maquettes", 
            //         "3ème consulation (1h) : choix de la maquette", 
            //         "Mise en place structure du projet et stylisation", 
            //         "Site responsive, adaptation tout support", 
            //         "Mise en place contenu textes & images", 
            //         "Optimisation du référencement (optionnel)", 
            //         "Mise en place outil de statistiques de visite (optionnel)", 
            //         "Accès à un nom de domaine + hébergement OU nom de domaine + hébergement + boîtes mails pro (optionnel)", 
            //         "Mise en ligne du site"
            //     ],
            // },

            {
                title :"SITE STANDARD",
                price:"1210",
                subscription:"",
                summary:"Blog - Portfolio - Catalogue",
                stack:"WORDPRESS - Pack 5 pages",
                tasks:[
                    "1ère Consultation (1h) : définition du projet (GRATUIT)", 
                    "Conception design avec un page builder (optionnel)",
                    "2ème Consultation (1h) : propositions de template OU d'idées design avec un page builder", 
                    "Mise en place du template OU design page builder",
                    "Mise en place contenu textes & images", 
                    "Site responsive, adaptation tout support", 
                    "Interface administrateur avec pack de plugin standard", 
                    "Tutoriel accès à un nom de Domaine + Hébergement OU nom de domaine + hébergement + boîtes mails pro (optionnel)***", 
                    "Optimisation du référencement (optionnel)", 
                    "Mise en place formulaire de contact (optionnel)", 
                    "Association du site aux réseaux sociaux (optionnel)", 
                    "Mise en place outil de statistiques de visite (optionnel)", 
                    "Mise en ligne du site", 
                    "Formation utilisation de Wordpress (optionnel)"
                ],
            },

            // {
            //     title :"SITE VITRINE PERSONNALISÉ",
            //     price:"1515",
            //     subscription:"",
            //     summary:"Blog - Portfolio - Présentation (produit/service)",
            //     stack:"Interface administrable - Pack 5 pages",
            //     tasks:[
            //         "1ère Consultation (1h) : définition du projet (GRATUIT)", 
            //         "2ème Consultation (1h) : propositions de design", 
            //         "Réalisation 2 à 3 maquettes", 
            //         "3ème consulation (1h) : choix de la maquette", 
            //         "Mise en place structure du projet et stylisation", 
            //         "Site responsive, adaptation tout support", 
            //         "Mise en place contenu textes & images",
            //         "Mise en place interface d'administration (optionnel)", 
            //         "Mise en place formulaire de contact (optionnel)", 
            //         "Optimisation du référencement (optionnel)", 
            //         "Mise en place outil de statistiques de visite (optionnel)", 
            //         "Accès à un nom de domaine + hébergement OU nom de domaine + hébergement + boîtes mails pro (optionnel)", 
            //         "Mise en ligne du site"
            //     ],
            // },

            {
                title :"E-COMMERCE STANDARD",
                price:"1830",
                subscription:"",
                summary:"Boutique en ligne",
                stack:"WORDPRESS - Pack 10 pages",
                tasks:[
                    "1ère Consultation (1h) : définition du projet (GRATUIT)", 
                    "Conception design avec un page builder (optionnel)",
                    "2ème Consultation (1h) : propositions de template OU d'idées design avec un page builder", 
                    "Mise en place du template OU design page builder",
                    "Mise en place contenu textes & images",
                    "Mise en place plugin de e-commerce", 
                    "Site responsive, adaptation tout support", 
                    "Interface administrateur avec pack de plugin standard", 
                    "Tutoriel accès à un nom de Domaine + Hébergement OU nom de domaine + hébergement+ boîtes mails pro (optionnel)***", 
                    "Optimisation du référencement (optionnel)", 
                    "Mise en place formulaire de contact (optionnel)", 
                    "Association du site aux réseaux sociaux (optionnel)", 
                    "Mise en place outil de statistiques de visite (optionnel)", 
                    "Mise en ligne du site", 
                    "Formation utilisation de Wordpress (optionnel)"
                ],
            },
            // {
            //     title :"E-COMMERCE PERSONNALISÉ",
            //     price:"2520",
            //     subscription:"",
            //     summary:"Boutique en ligne",
            //     stack:"Interface personnalisée - Pack 10 pages",
            //     tasks:[
            //         "1ère Consultation (1h) : définition du projet (GRATUIT)", 
            //         "2ème Consultation (1h) : propositions de design", 
            //         "Réalisation 2 à 3 maquettes", 
            //         "3ème consulation (1h) : choix de la maquette", 
            //         "Mise en place structure du projet et stylisation", 
            //         "Site responsive, adaptation tout support", 
            //         "Mise en place contenu textes & images",
            //         "Mise en place interface d'administration (optionnel)",
            //         "Mise en place 1 ou 2 moyen(s) de paiement (paypal/stripe)", 
            //         "Mise en place formulaire de contact (optionnel)", 
            //         "Optimisation du référencement (optionnel)", 
            //         "Mise en place outil de statistiques de visite (optionnel)", 
            //         "Accès à un nom de domaine + hébergement OU nom de domaine + hébergement + boîtes mails pro (optionnel)", 
            //         "Mise en ligne du site"
            //     ],
            // },

            {
                title :"FORFAIT MAINTENANCE**",
                price:"400",
                subscription:"/AN",
                summary:"Mise à jour, débogage",
                stack:"Sauvegardes et hébergement web",
                tasks:[
                    "Mises à jour site internet & plugins", 
                    "Petites modifications graphiques ou de contenu", 
                    "Sauvegarde mensuelle de la base de données ",
                    "Restauration en cas de piratage", 
                ],

            },

            {
                title :"CRÉATION PERSONNALISÉE",
                price:"400",
                subscription:"/JOUR",
                summary:"Application web ou mobile",
                stack:"Consultation stratégie digitale",
                tasks:[
                ],

            },

            

            

            
        ]

        let pricesCardsBasics =  deliveriesType.map((item,i)=>(

            item.subscription === "" ? 

                <PricesCard 
                sectionName="delivery" 
                index={i} title={item.title} 
                price={item.price} 
                subscription={item.subscription} 
                summary={item.summary} 
                stack={item.stack} 
                tasks={item.tasks} 
                onClick={() => this.handleClickOpen()}
                />
            :

                null 
        )) 
        
        let pricesCardsSpecial = deliveriesType.map((item,i)=>(

            item.subscription !== "" ? 

                <PricesCard 
                sectionName="special_delivery" 
                index={i} title={item.title} 
                price={item.price} 
                subscription={item.subscription} 
                summary={item.summary} 
                stack={item.stack} 
                tasks={item.tasks} 
                onClick={() => this.handleClickOpen()}
                />
                
            :

                null
        )) 
        


        return(
            <div className="prices_section" id="prices">
                
                <h2>TARIFS</h2>
                <h3 id="quotes">Un tarif adapté à votre besoin</h3>
               
                <div className="hr"><p></p></div>
                <div className="quotesBar">
                    <h4>Un projet ? <a href="#quotes" className="quotes" onClick={() => this.handleClickOpen()}>Demandez un devis</a> (GRATUIT)</h4>
                </div>
                <div className="quotesForm" style={this.state.showQuotesForm === false ? style1.quotesForm : null}>
                    <PricesForm onClick={() => this.handleClickClose()}/>
                </div>
                <div className="deliveries">

                    <div className="deliveries_column">

                        <div className="deliveries_section">
                            {pricesCardsBasics}
                        </div>
                        <div className="special_deliveries_section">
                            {pricesCardsSpecial}
                        </div>

                    </div>

                </div>
                    <div className="asterisk">
                        <div className="additionalInformation">
                            <p><b>*</b> Paiement possible en plusieurs fois - Tarif indicatif, peut varier en fonction des demandes et besoins (voir CGV envoyées avec le devis)</p>
                            <p><b>**</b> Forfait maintenance (engagement 12 mois renouvelable)</p>
                            <p><b>***</b> Les frais liés à l'hébergement et le nom de domaine ne sont pas compris dans les offres. Un tutoriel vous sera délivré pour procéder à leur achat. Vous disposerez ainsi librement de votre site.</p>
                            <br/>
                            <p className="addInfo">Le devis contiendra une estimation détaillée du coût rapporté à chaque tâche.</p>
                            <p className="addInfo">Les solutions entièrement personnalisées (hors Wordpress) peuvent faire l'objet d'une négociation sur la cession des droits de propriété intellectuelle en ce qui concerne le code source.</p>
                        </div>
                    </div>

            </div>
        )
    }


}

export default Prices
