import React from 'react';

class MandatoryInfoLinks extends React.Component {

    render(){
        


        return(

            <div className="footerThirdLine" id="mentions_légales">

                    <a href="#mentions_légales" onClick={() => this.props.onClick(0)}>MENTIONS LÉGALES & CGU</a>
                    {/* <p>・</p> */}
                    {/* <a href="#CGV" onClick={() => this.props.onClick(1)}>CGV</a>
                    <p>・</p> */}
                    {/* <a href="#copyright">COPYRIGHT</a> */}

            </div>

        )
    }

}

export default MandatoryInfoLinks
