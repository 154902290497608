import React from 'react';

// SCHEMA
import {basicSchema} from '../../schemas/schemas.js'; 

//FORMIK
import {useFormik} from 'formik';


//MATERIAL UI
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';


//ICON
import {FaUser} from 'react-icons/fa';
import {SiMaildotru} from 'react-icons/si';
import {MdPhoneAndroid, MdOutlineClose} from 'react-icons/md';
import {ImLink} from 'react-icons/im';
import {IoBusiness} from 'react-icons/io5';

//EMAIL JS
import emailjs from '@emailjs/browser';


function PricesForm(props){

    const theme = createTheme({
        typography: {
          fontFamily: 'Plus Jakarta Sans, sans-serif',
          fontSize: 12,

        },
      });

      const onSubmit = async (values,actions) => {
        console.log(values)

        alert('Votre demande a bien été transmise, vous recevrez une réponse dans les plus brefs délais');

        const templateId = process.env.REACT_APP_TEMPLATE_ID;
        const serviceId = process.env.REACT_APP_SERVICE_ID;

        sendRequest(serviceId,templateId, {
            firstname : values.firstname,
            lastname : values.lastname,
            email : values.email,
            contactType : values.contactType,
            firm : values.firm,
            phoneNumber : values.phoneNumber,
            link : values.link,
            projectType : values.projectType,
            message : values.message, 
            termsAndConditionsOfUse : values.termsAndConditionsOfUse,
        })

        await new Promise((resolve) => setTimeout(resolve,1000))
        actions.resetForm()

      }

      const sendRequest = (serviceId,templateId,variables)=> {

          emailjs.send(serviceId,templateId,variables,process.env.REACT_APP_PUBLIC_KEY)
          .then((res) => {
            console.log('success');
          })
          .catch((err)=> console.error("Erreur dans l'envoi"));
      }

    const {values, errors, touched, isSubmitting,  handleBlur, handleChange, handleSubmit} = useFormik({

        initialValues: {
          firstname: '',
          lastname: '',
          email: '',
          contactType:'',
          firm:'',
          phoneNumber:'',
          link:'',
          projectType:'',
          message:'',
          termsAndConditionsOfUse:'',

        },
        validationSchema : basicSchema,
        onSubmit,
      });

      const conditionnalStyle = {
          inputs : {
            backgroundColor:"rgba(134,198,210,0.5)",

          }, 
          errorMessages : {
              color:'#86C6D2', 
              fontStyle:'italic', 
              fontSize:13,
              marginTop:'2%',
          }
      }



    return(
        <ThemeProvider theme={theme}>

            <form onSubmit={handleSubmit} className="quotesInput">

                <div className="quotesFormCloseBtnPosition">
                    <MdOutlineClose size={20} onClick={()=>props.onClick()} className="quotesFormCloseBtn"/>
                </div>
            
                <div className="contactDetails">

                    <h5>VOS COORDONNÉES</h5>

                    <div className="quotesFormHrBar"></div>

                    <div className="input">

                        
                        <FaUser size={20} className="quotesFormIcon"/>
                        <input
                            className="quotesFormFirstName"
                            id="firstName"
                            name="firstname"
                            placeHolder="Prénom"
                            type="text"
                            onChange={handleChange}
                            value={values.firstName}
                            onBlur={handleBlur}
                            style={errors.firstname && touched.firstname ? conditionnalStyle.inputs : null }
                        /> 
                                    

                            
                        <input
                            className="quotesFormLastName"
                            id="lastName"
                            name="lastname"
                            placeHolder="Nom"
                            type="text"
                            onChange={handleChange}
                            value={values.lastName}
                            onBlur={handleBlur}
                            style={errors.lastname && touched.lastname ?  conditionnalStyle.inputs : null }
                        />

                    </div>

                    <div className="errorMessages">
                        <div>
                            {errors.firstname && touched.firstname ?  <p style={conditionnalStyle.errorMessages}>{errors.firstname}</p> : null}                      
                        </div>
                        <div className="errorMessage">
                            {errors.lastname && touched.lastname ?  <p style={conditionnalStyle.errorMessages}>{errors.lastname}</p> : null}                      
                        </div>
                    </div>



                    <div className="quotesFormRadio">

                        <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        id="my-radio-group"
                        defaultValue="individual"
                        >

                            <FormControlLabel 
                            name="contactType"
                            control={<Radio />} 
                            label="Particulier" 
                            onChange={handleChange}
                            value="particulier"
                            onBlur={handleBlur}
                            />

                            <FormControlLabel 
                            name="contactType"
                            control={<Radio />} 
                            label="Association" 
                            onChange={handleChange}
                            value="association"
                            onBlur={handleBlur}
                            />
                            <FormControlLabel 
                            name="contactType"
                            control={<Radio />} 
                            label="Entreprise" 
                            onChange={handleChange}
                            value="entreprise"
                            onBlur={handleBlur}
                            />

                        </RadioGroup> 

                    </div>
                    {errors.contactType && touched.contactType ?  <p style={conditionnalStyle.errorMessages}>{errors.contactType}</p> : null}                      

                    
                
                      
                    <div className="input">
                        <IoBusiness size={20} className="quotesFormIcon"/>
                        <input
                            className="quotesFormInput"
                            id="firm"
                            name="firm"
                            placeHolder="Dénomination entreprise/association"
                            type="text"
                            onChange={handleChange}
                            value={values.firm}
                            onBlur={handleBlur}
                            style={errors.firm && touched.firm ? conditionnalStyle.inputs : null }
                        />
                    </div>
                    
                    
                    <div className="input">
                        <SiMaildotru size={20} className="quotesFormIcon"/>
                        <input
                            className="quotesFormInput"
                            id="email"
                            name="email"
                            placeHolder="E-mail"
                            type="email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                            style={errors.email && touched.email ? conditionnalStyle.inputs : null }
                        />
                    </div>
                    {errors.email && touched.email ?  <p style={conditionnalStyle.errorMessages}>{errors.email}</p> : null}                      


                    <div className="input">

                        <MdPhoneAndroid size={20} className="quotesFormIcon"/>
                        <input
                            className="quotesFormInput"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeHolder="Téléphone ex (FR) : +33646114535 ..."
                            type="tel"
                            onChange={handleChange}
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            style={errors.phoneNumber && touched.phoneNumber ? conditionnalStyle.inputs : null }

                        />

                    </div>

                    {errors.phoneNumber && touched.phoneNumber ?  <p style={conditionnalStyle.errorMessages}>{errors.phoneNumber}</p> : null}                      

                    <div className="input">
                        <ImLink size={20} className="quotesFormIcon"/>
                        <input
                            className="quotesFormInput"
                            id="link"
                            name="link"
                            placeHolder="Lien site internet ex : https://www.marine-jacquens.com/"
                            type="url"
                            onChange={handleChange}
                            value={values.link}
                            onBlur={handleBlur}
                            style={errors.link && touched.link ? conditionnalStyle.inputs : null }

                        />   
                    </div>

                    {errors.link && touched.link ?  <p style={conditionnalStyle.errorMessages}>{errors.link}</p> : null}                      


                </div>
                <div className="quotesFormProject">

                    <h5>VOTRE PROJET</h5>
                    <div className="quotesFormHrBar"></div>

                    <select id="quotesFormProjectType" name="projectType" onBlur={handleBlur} onChange={handleChange} style={errors.projectType && touched.projectType ? conditionnalStyle.inputs : null }>
                        <option value="">--Sélectionnez une option--</option>
                        <option value="Landing page">Landing page</option>
                        <option value="Site vitrine">Site vitrine</option>
                        <option value="Site standard wordpress">Site standard wordpress</option>
                        <option value="Vitrine administrable">Vitrine administrable</option>
                        <option value="E-commerce CMS">E-commerce wordpress</option>
                        <option value="E-commerce Personnalisé">E-commerce personnalisé</option>
                        <option value="Solution Personnalisé">Solution personnalisée</option>
                    </select>

                    {errors.projectType && touched.projectType ?  <p style={conditionnalStyle.errorMessages}>{errors.projectType}</p> : null}                      


                    <textarea 
                        name="message" 
                        placeholder='Description détaillée de votre activité et de votre projet de site ou application'
                        onChange={handleChange}
                        value={values.message}
                        onBlur={handleBlur}
                        style={errors.message && touched.message ?  conditionnalStyle.inputs : null }
                    >
                    </textarea>

                    {errors.message && touched.message?  <p style={conditionnalStyle.errorMessages}>{errors.message}</p> : null}             

                    <div className="priceFormCheckboxes">

                            <Checkbox name="termsAndConditionsOfUse" value={values.termsAndConditionsOfUse} onChange={handleChange} onBlur={handleBlur}/>
                            <label for="scales">J'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler. J'ai également pris connaissance des <a href="#mentions_légales">mentions légales</a> et <a href="#mentions_légales">conditions générales d'utilisation</a> du site.</label>
                    </div>

                    {errors.termsAndConditionsOfUse && touched.termsAndConditionsOfUse?  <p style={conditionnalStyle.errorMessages}>{errors.termsAndConditionsOfUse}</p> : null}

                    <div className="quotesFormSubmit">
                        <button disabled={isSubmitting} type="submit">ENVOYER MA DEMANDE DE DEVIS</button>
                    </div>

                </div>
            
            

                       

     </form>

        </ThemeProvider>
        
    )

}

export default PricesForm