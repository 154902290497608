import React from 'react';

class Skills extends React.Component {

    render(){

        let skills_details = [

            {
                title:"CONCEPTION GRAPHIQUE & WEBDESIGN",
                paragraph:"Templates web et mobile, bannières web, newsletters...",
            },

            {
                title:"CRÉATION DE PROJETS WEB, WEB MOBILE ET APPLICATIONS",
                paragraph:"Site vitrine, e-commerce, blog, application mobile.",
            },

            {
                title:"INTÉGRATION (X)HTML / CSS",
                paragraph:"Selon les critères d'éligibilité du W3C Markup Validator.",
            },
            
            {
                title:"CRÉATION/MISE EN PLACE D'INTERFACES D'ADMINISTRATION",
                paragraph:"PHP - MYSQL - AJAX / STRAPI",
            },

            {
                title:"MISE EN PLACE / MODIFICATION / GESTION DE CMS",
                paragraph:"Wordpress, Strapi",
            },
            
            {
                title:"DYNAMISME DES PAGES",
                paragraph:"Javascript - jQuery / Ajax",
            },

            {
                title:"AUDIT RÉFÉRENCEMENT NATUREL SEO & ERGONOMIE",
                paragraph:"Optimisation des mots-clés, accessibilité & ergonomie des pages web.",
            },

            {
                title:"CONCEPTION MULTI-PLATEFORMES",
                paragraph:"Compatible tous supports : PC, laptop, tablette & mobile.",
            }
        ]

        let languages_skills_bar = [
            {
                languages : "HTML - CSS",
                progress:"90%",
            },
            {
                languages : "PHP",
                progress:"80%",
            },
            {
                languages : "SQL (MySQL - MariaDB)",
                progress:"85%",
            },
            {
                languages : "JAVASCRIPT/JQUERY - AJAX",
                progress:"75%",
            },
            {
                languages : "PYTHON",
                progress:"70%",
            },
        ]

        let other_skills_bar = [

            {
                cms: "REACT",
                progress:"85%"
            },
            {
                cms: "REACT NATIVE",
                progress:"80%"
            },
            {
                cms: "STRAPI",
                progress:"75%"
            },
            {
                cms: "WORDPRESS",
                progress:"90%"
            },
        ]
        

        return(
            <div className="skills_section" id="skills">

                <div className="sectionTitle">
                    <h2>MES COMPÉTENCES</h2>
                    <h3>Des compétences à votre service</h3>
                </div>

                <div className="hr"><p></p></div>
                <div className="skills_list">
                    <div  className="skills_column">
                        <h4>Domaines de compétences</h4>
                        {skills_details.map((item,index)=>(
                            <div key={index}>
                                <h5>{item.title}</h5>
                                <p>{item.paragraph}</p>
                            </div>
                        ))}
                        
                    </div>
                    <div className="skills_progress">
                        <h4>Langages</h4>
                            {
                                languages_skills_bar.map((item,index)=>(

                                    <div key={index} className="progress_bar">
                                        <h5 style={{width:item.progress}}>{item.languages}</h5>
                                    </div>

                                ))
                            }
                        <h4>Frameworks & CMS</h4>
                        {
                            other_skills_bar.map((item,index)=>(

                                    <div key={index} className="progress_bar">
                                        <h5 style={{width:item.progress}}>{item.cms}</h5>
                                    </div>

                                ))
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default Skills