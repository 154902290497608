import React, { useState, useEffect } from 'react';


// REACT ICONS
import {IoMenu} from 'react-icons/io5';
import {ImHome} from 'react-icons/im';
import {GiSkills} from 'react-icons/gi';
import {RiSlideshowLine} from 'react-icons/ri';
import {FaTasks,FaCashRegister} from 'react-icons/fa';
import {MdOutlineClose} from 'react-icons/md';



function Navbar(){


  const [isHover, setIsHover] = useState(false);
  const [navbarColor,setNavbarColor] = useState(false);
  const [navbarItem,setNavbarItem] = useState(0);
  const [sidebarMenu, setSidebarMenu] = useState(false);


   const handleMouseEnter = () => {
      setIsHover(true);
   };
   const handleMouseLeave = () => {
      setIsHover(false);
   };

  const style1 = {
    navbar:{
      backgroundColor: 'white',
      transition:'0.5s',
      boxShadow: '0px 2.5px 2px rgba(0,0,0,0.1)',
    },
    anchor:{
      color:'#86C6D2',
      transition:'0.5s',
    },
    active:{
      color:'#86C6D2',
      borderColor:'#86C6D2',
    },
    navbarMenuEffect:{
      maxWidth: isHover && sidebarMenu === false ? "60%" : null,
    },
    sidebarMenuDisplay:{
      display:"none",
    },
    sidebarMenuStyle:{
      width:"55%",
      transition:"1s",
    }
    
  }

  const style2 = {
    navbar:{
      backgroundColor: 'rgba(255, 255, 255, 0.167)',
      transition:'0.5s',
    },

    
  }
  

  function displaySidebarMenu(){
    setSidebarMenu(!sidebarMenu)  
  }
  
  useEffect(() => {


      function handleResize() {

        if(window.scrollY > 0.1){
          setNavbarColor(true)  
        }else{
          setNavbarColor(false)  
        }

        if(window.scrollY < 600){
          setNavbarItem(0)  
        }else if(window.scrollY > 600 && window.scrollY < 1900 ){
          setNavbarItem(1)  
        }else if(window.scrollY > 1900 && window.scrollY < 2900){
          setNavbarItem(2) 
        }else if(window.scrollY > 2900 && window.scrollY < 4000){
          setNavbarItem(3) 
        }else if(window.scrollY > 4000 && window.scrollY < 5900){
          setNavbarItem(4) 
        }
      
      }
  
      window.addEventListener('scroll', handleResize);

      return ()=>{
        clearInterval(handleResize())
      }

  },[])






  const itemMenu = [

    {
      name:<ImHome size={25}/>,
      link:"#home",
      icon:<ImHome size={25}/>,
    },
    {
      name:"SERVICES",
      link:"#services",
      icon:<FaTasks size={25}/>,
    },
    {
      name:"COMPÉTENCES",
      link:"#skills",
      icon:<GiSkills size={25}/>,
    },
    {
      name:"PORTFOLIO",
      link:"#portfolio",
      icon:<RiSlideshowLine size={25}/>,
    },
    {
      name:"TARIFS",
      link:"#prices",
      icon:<FaCashRegister size={25}/>,
      
    },
    // {
    //   name:"À PROPOS",
    //   link:"#portfolio",
    //   icon:<GiRead size={25}/>,
    // },
    // {
    //   name:"CONTACT",
    //   link:"#portfolio",
    //   icon:<GoMailRead size={25}/>,
    // },
  ]


    return(
        
        <div className="navbar">

            <div className="menu" style={sidebarMenu === true ? style1.sidebarMenuStyle : null}>

                <div className="sidebarMenu" style={sidebarMenu === false ? style1.sidebarMenuDisplay : null}>

                  {
                    itemMenu.map((item,i)=>(

                    navbarItem === i ?

                    <a index={i} style={style1.active} href={item.link}>{item.name}</a>
                    : 
                    <a index={i} style={style1.anchor} href={item.link}>{item.name}</a>

                    ))
                  }

                </div>
                <div  className="menu_button" style={style1.navbarMenuEffect} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <div className="menuButtonIcon">

                    {
                      sidebarMenu === true ? 
                      <MdOutlineClose size={25} onClick={displaySidebarMenu}/>
                      :
                      <IoMenu size={25} onClick={displaySidebarMenu}/>

                    }
                  </div>
                </div>

            </div>
            <div className="navbar_display" style={navbarColor === true ? style1.navbar : style2.navbar}>

                {navbarColor === true ? 

                  itemMenu.map((item,i)=>(

                    navbarItem === i ?

                    <a index={i} style={style1.active} href={item.link}>{item.name}</a>
                    : 
                    <a index={i} style={style1.anchor} href={item.link}>{item.name}</a>

                    ))

                : 
                    
                    itemMenu.map((item,i)=>(

                    navbarItem === i ?

                    <a index={i} style={style1.active} href={item.link}>{item.name}</a>
                    : 
                    <a index={i} href={item.link}>{item.name}</a>

                    ))
                
                }
                

            </div>       
        </div>
    )

}

export default Navbar